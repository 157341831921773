<template>
  <ProjectHeader />

  <ProjectFooter />
</template>

<script>
import ProjectHeader from "../../components/project_header.vue";
import ProjectFooter from "../../components/project_footer.vue";

export default {
  components: {
    ProjectHeader,
    ProjectFooter,
  },
  setup() {},
};
</script>

<style lang="scss">
@import "../../assets/styles/theming.scss";

</style>
